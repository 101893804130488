var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"rollen"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"8"}},[_c('CIcon',{attrs:{"name":"cil-storage"}}),_c('h5',[_vm._v("Übersicht über aller Rollen und Rechte")])],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CTabs',[_c('CTab',[_c('template',{slot:"title"},[_vm._v(" Report/Verwaltung ")]),_c('CDataTable',{attrs:{"tableFilter":{ label: 'Suchen:', placeholder: 'hier tippen...' },"column-filter":"","sorter":"","striped":"","hover":"","items":_vm.items.report,"fields":_vm.fields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('NoData')]},proxy:true},{key:"access",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"","width":"1%"}},[(item.access === 'ja')?_c('CBadge',[_vm._v("Ja "),_c('svg',{staticClass:"bi bi-check2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"}})])]):_vm._e(),(item.access === 'nein')?_c('CBadge',[_vm._v("Nein "),_c('svg',{staticClass:"bi bi-x",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})])]):_vm._e(),(item.access !== 'nein' && item.access !== 'ja')?_c('CBadge',[_vm._v(_vm._s(item.access))]):_vm._e()],1)]}}])},[_c('template',{slot:"tableFilter"},[_vm._v(" Test ")])],2)],2),_c('CTab',[_c('template',{slot:"title"},[_vm._v(" Mitarbeiter/Monteure ")]),_c('CDataTable',{attrs:{"tableFilter":{ label: 'Suchen:', placeholder: 'hier tippen...' },"striped":"","hover":"","column-filter":"","sorter":"","items":_vm.items.mitarbeiter,"fields":_vm.fields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('NoData')]},proxy:true},{key:"access",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"","width":"1%"}},[(item.access === 'ja')?_c('CBadge',[_vm._v("Ja "),_c('svg',{staticClass:"bi bi-check2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"}})])]):_vm._e(),(item.access === 'nein')?_c('CBadge',[_vm._v("Nein "),_c('svg',{staticClass:"bi bi-x",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})])]):_vm._e(),(item.access !== 'nein' && item.access !== 'ja')?_c('CBadge',[_vm._v(_vm._s(item.access))]):_vm._e()],1)]}}])})],2),_c('CTab',[_c('template',{slot:"title"},[_vm._v(" Auszubildende/Helfer ")]),_c('CDataTable',{attrs:{"tableFilter":{ label: 'Suchen:', placeholder: 'hier tippen...' },"striped":"","column-filter":"","sorter":"","hover":"","items":_vm.items.kalender,"fields":_vm.fields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('NoData')]},proxy:true},{key:"access",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"","width":"1%"}},[(item.access === 'ja')?_c('CBadge',[_vm._v("Ja "),_c('svg',{staticClass:"bi bi-check2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"}})])]):_vm._e(),(item.access === 'nein')?_c('CBadge',[_vm._v("Nein "),_c('svg',{staticClass:"bi bi-x",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})])]):_vm._e(),(item.access !== 'nein' && item.access !== 'ja')?_c('CBadge',[_vm._v(_vm._s(item.access))]):_vm._e()],1)]}}])})],2),_c('CTab',[_c('template',{slot:"title"},[_vm._v(" Admin ")]),_c('CDataTable',{attrs:{"tableFilter":{ label: 'Suchen:', placeholder: 'hier tippen...' },"columnFilter":"","striped":"","hover":"","items":_vm.items.admin,"fields":_vm.fields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('NoData')]},proxy:true},{key:"access",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"","width":"1%"}},[(item.access === 'ja')?_c('CBadge',[_vm._v("Ja "),_c('svg',{staticClass:"bi bi-check2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"}})])]):_vm._e(),(item.access === 'nein')?_c('CBadge',[_vm._v("Nein "),_c('svg',{staticClass:"bi bi-x",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})])]):_vm._e(),(item.access !== 'nein' && item.access !== 'ja')?_c('CBadge',[_vm._v(_vm._s(item.access))]):_vm._e()],1)]}}])})],2)],1)],1)],1)],1),_c('CCardFooter',[_c('CRow',[_c('CCol',{attrs:{"sm":"2"}},[_c('CButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":function($event){return _vm.$router.push('/mitarbeiter/uebersicht')}}},[_c('svg',{staticClass:"bi bi-arrow-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"}})]),_vm._v(" zurück")]),_c('br'),_c('br')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }